<template>
    <div class="associatedorder">
        <div class="storeCenter_item_top">
          <div class="storeCenter_item_top_left">
            <img v-if="companyinfo.companyLogo" :src="companyinfo.companyLogo" alt="" />
            <img v-else src="../../../assets/image/Group 3237.png" alt="" />
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <h5 style="display: inline-block; margin: 0; font-size: 18px">
                  {{ companyinfo.companyFullName }}
                </h5>
              </div>
              <div class="storeCenter_item_top_left_flex_tag">
                <span v-for="(o, index) in companyinfo.labelList" :key="index">
                  <span class="office">{{ o }}</span>
                </span>
                <el-popover placement="right" width="230" trigger="click">
                  <div
                    class="hidden_label_box"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-content: center;
                      flex-wrap: wrap;
                    "
                  >
                    <span
                      class="office"
                      style="
                        border: 1px solid #559eff;
                        color: #4e93fb;
                        box-sizing: border-box;
                        padding: 1px 6px;
                        display: block;
                        font-size: 14px;
                        font-weight: 400;
                        border-radius: 4px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                      "
                      v-for="(o, index) in companyinfo.labelList && companyinfo.labelList.length
                        ? companyinfo.labelList.filter((el, index) => index > 3)
                        : []"
                      :key="index"
                      >{{ o }}</span
                    >
                  </div>
                  <span slot="reference" v-if="companyinfo.labelList && companyinfo.labelList.length > 4">{{
                    companyinfo.labelList.length - 4
                  }}</span>
                </el-popover>
              </div>
              <p class="address">
                <span style="padding-left: 0px"> {{ companyinfo.legalPerson }}</span>
                <span> {{ companyinfo.companyPhone }}</span>
                <span> {{ companyinfo.province }}-{{ companyinfo.city }}</span>
                <span>
                  <a style="color: #4e93fb" href="">{{ companyinfo.officialWebsite }}</a>
                </span>
              </p>
            </div>
          </div>
          <div class="storeCenter_item_top_right">
            <div class="account_cycle" @click="beforeAccountDialog(companyinfo.id)">
              <img
                src="../../../assets/image/Group-1.png"
                alt=""
                style="width: 16px; height: 16px; margin-right;: 10px;vertical-align: top;"
              />
              账号周期：{{ companyinfo.beginTime }}至{{ companyinfo.deadline }}
              <span v-if="companyinfo.prompt" style="color: #fd702b; width: 80px; display: inline-block">
                <img
                  src="../../../assets/image/Group.png"
                  alt=""
                  style="width: 16px; height: 16px; margin-right;: 10px;vertical-align: top;"
                />即将到期</span
              >
              <span v-else style="width: 80px; display: inline-block"></span>
            </div>

           
          </div>
          
        </div>
        <div class="comble_txt">
          
            <el-form-item label="订单类型:" prop="">
              <el-radio-group v-model="queryInfo.channelType" @change="search">
                <el-radio-button
                  label="全部"
                  :value="''"
                  >全部</el-radio-button
                >
                <el-radio-button
                  :label="item.value"
                  v-for="(item, index) in InformationTypes"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
          
          </div>
    </div>
  </template>
  
  <script>
  import {
  getCompanyDetail,
  
  
} from '@/api/datement'
  export default {
    name: 'AssociatedOrder',
    computed: {
    id() {
      return this.$route.query.id
    }
  },
    data() {
      return {
        companyinfo:{},//企业信息
     
      }
    },
    created() {
        getCompanyDetail({ id: this.id }).then(({ data: res }) => {
            this.companyinfo=res.data
      })
    },
    methods: {
 

 
    }
  }
  </script>
  
  <style lang="less" scoped>
   .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;

        img {
          width: 90px;
          height: 90px;
          border-radius: 4px;
          margin-right: 14px;
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            padding-top: 13px;

            span {
              box-sizing: border-box;
              padding: 1px 6px;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }

          .address {
            font-size: 14px;
            span {
              padding: 0px 10px;
            }
          }
        }
      }
      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;
        .account_cycle {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          /* 灰色字体 */

          color: #969495;
        }
        .operation_area {
          margin-top: 30px;
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
      }
    }
  </style>
  